import React from 'react';
import PropTypes from 'prop-types';

import useWindowSize from 'components/hooks/useWindowSize';

const WebOnly = ({ url }) => {
	return (
		<object
			data={`${url}#toolbar=1&navpanes=0&scrollbar=0`}
			type="application/pdf"
			className="w-full h-screen"
		/>
	);
};

const ResposiveOnly = ({ url, height }) => {
	return (
		<iframe
			src={`https://docs.google.com/gview?embedded=true&url=${url}`}
			className="w-full mt-14 md:mt-0"
			style={{ height: height >= 1024 ? '50rem' : '30rem' }}
			frameBorder={1}
			scrolling="no"
			seamless=""
		/>
	);
};

/**
 *
 * @param {string} url
 * @returns Component PDF viewer
 */
const PdfViewer = ({ url }) => {
	const { width, height } = useWindowSize();

	if (width <= 1024) return <ResposiveOnly url={url} height={height} />;

	return <WebOnly url={url} />;
};

PdfViewer.propTypes = {
	url: PropTypes.string.isRequired
};

export default PdfViewer;
