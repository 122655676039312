import React from 'react';
import TermsConditionPrivate from './TermsConditionPrivate';
import TermsConditionAlly from './TermsConditionAlly';
import TermsConditionAllyMaster from './TermsConditionAllyMaster';

const TermsDisplayer = ({
	isAllyBasic = false,
	isAllyMaster = false,
	// isACUNSA = false,
	is_affiliation = false,
	isPdf = false,
	handleClose = null
}) => {
	return (
		<>
			{isAllyBasic && <TermsConditionAlly />}
			{isAllyMaster && <TermsConditionAllyMaster />}
			{!isAllyBasic && !isAllyMaster && <TermsConditionPrivate />}
			{!isPdf && is_affiliation && (
				<div className="flex justify-evenly my-10 w-full">
					<button
						className="secondary-button-nav mr-2"
						onClick={() => handleClose(false, 'acceptTerms')}
					>
						I do not accept{' '}
					</button>
					<button
						className="white-button-nav"
						onClick={() => handleClose(true, 'acceptTerms')}
					>
						I agree{' '}
					</button>
				</div>
			)}
		</>
	);
};

export default TermsDisplayer;
