import React from 'react';
import PdfViewer from '../pdfViewer/PdfViewer';

const TermsConditionAllyMaster = () => {
	return (
		<div data-testid="TermsConditionAllyMaster">
			<PdfViewer url="https://asistensi-content-delivery.s3.amazonaws.com/documents/Aliados-Maestros-TCs-ENG-ESP.pdf" />
		</div>
	);
};

export default TermsConditionAllyMaster;
