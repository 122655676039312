import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { Document, Page } from 'react-pdf';
import { SpinnerV } from 'components/widgets';

const styles = (theme) => ({
	cancelButton: {
		margin: theme.spacing(2),
		background: 'transparent !important',
		color: `#3c3c3c`,
		fontSize: '16px',
		textTransform: 'capitalize',
		padding: '5px 20px'
	},
	table: {
		borderCollapse: 'collapse',
		margin: '0 auto',
		textAlign: 'center',
		overflow: 'auto'
	},
	margin: {
		marginLeft: '30px'
	},
	title: {
		textAlign: 'center'
	},
	textJustify: {
		textAlign: 'justify',
		marginLeft: '20px',
		marginRight: '20px'
	}
});

const TermsConditionAllyPDF = (pdf_url_ally) => {
	const pdf_url = `${process.env.REACT_APP_BASE_PATH_FILES}${pdf_url_ally.pdf_tc}`;
	const [numPages, setNumPages] = useState(null);
	const [totalNumber, settotalNumber] = useState([]);

	let onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
		let bucle = [];
		for (let i = 1; i <= numPages; i++) {
			bucle.push(i);
		}
		settotalNumber(bucle);
	};
	let width_screen = 800;

	return (
		<div>
			<Document
				file={pdf_url}
				onLoadSuccess={onDocumentLoadSuccess}
				loading={<SpinnerV />}
			>
				{totalNumber.map((i) => {
					return <Page key={`key_${i}`} pageNumber={i} width={width_screen} />;
				})}
			</Document>
		</div>
	);
};

TermsConditionAllyPDF.propTypes = {
	classes: PropTypes.object.isRequired,
	fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog({ breakpoint: 'xs' })(
	withStyles(styles)(TermsConditionAllyPDF)
);
