import React from 'react';
import PdfViewer from '../pdfViewer/PdfViewer';

const TermsConditionAlly = () => {
	return (
		<div data-testid="TermsConditionAlly">
			<PdfViewer url="https://asistensi-content-delivery.s3.amazonaws.com/documents/Aliados-T_Cs-ENG-ESP.pdf" />
		</div>
	);
};

export default TermsConditionAlly;
