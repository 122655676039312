import React from 'react';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions
} from '@material-ui/core';
import { PdfViewer } from 'components/pdfViewer';

const logo = '/static/images/Asistensi_logotipo.svg';

const PATH_FILES = process.env.REACT_APP_BASE_PATH_FILES_DOCS;

// const URL = `${PATH_FILES}/documents/Membership+Terms+and+Conditions.pdf`; URL DOC ASISTENSI FREE
const URL = `${PATH_FILES}PH/quotation/02.03. WEB - Privacy policy - ENG.ESP - v.17.05.2022_F.pdf`;

const QuotationPrivacyPolicyDialog = (props) => {
	const { fullScreen, open, handleClose } = props;

	return (
			<Dialog
				open={open}
				maxWidth="md"
				fullWidth={true}
				onClose={handleClose}
				disableBackdropClick={true}
				disableEscapeKeyDown={true}
				fullScreen={fullScreen}
			>
				<DialogTitle className="text-gray-800 font-bold">
					<img
						loading="lazy"
						src={logo}
						alt="Asistensi_logotype"
						className="h-12 w-auto"
					/>
				</DialogTitle>
				<DialogContent>{<PdfViewer url={URL} />}</DialogContent>
				<DialogActions>
					<button className="secondary-button-nav" onClick={handleClose}>
						Close
					</button>
				</DialogActions>
			</Dialog>
	);
};

QuotationPrivacyPolicyDialog.propTypes = {
	fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog({ breakpoint: 'xs' })(
	QuotationPrivacyPolicyDialog
);
