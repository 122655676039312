import React from 'react';

import { PdfViewer } from 'components/pdfViewer';

const PATH_FILES = process.env.REACT_APP_DELIVERY_BASE_PATH_FILES;
console.log('PATH_FILES', PATH_FILES);

const TermsConditionPrivate = () => {
	const URL = `${PATH_FILES}PH/documents/PHI-+TCs+-+Plan+ASISTENSI.pdf`;

	return (
		<div className={'mx-4'}>
			<PdfViewer url={URL} />
		</div>
	);
};

export default TermsConditionPrivate;
